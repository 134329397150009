import {
  FIND_TASKS,
  FIND_TASKS_BY_ID,
  GET_TASKS_FAILURE,
  GET_TASKS_REQUEST,
  GET_TASKS_SUCCESS,
  SHOW_CHILDRENS,
  TOGGLE_STRUCTURES,
  CLEAR_SEARCH
} from "../types/tasks";

export const findTask = (search) => ({
  type: FIND_TASKS,
  search
});

export const findTaskByID = (id, recursive) => ({
  type: FIND_TASKS_BY_ID,
  id,
  recursive
});

export const showChildrens = (item) => ({
  type: SHOW_CHILDRENS,
  item
});

export const getTasksRequest = (id, timeStart) => ({
  type: GET_TASKS_REQUEST,
  payload: {
    id,
    timeStart
  }
});

export const getTasksSuccess = (payload) => ({
  type: GET_TASKS_SUCCESS,
  payload
});

export const toggleStructures = (toggle) => ({
  type: TOGGLE_STRUCTURES,
  toggle
});

export const clearSearch = () => ({
  type: CLEAR_SEARCH,
});

export const getTasksFailure = (error) => ({
  type: GET_TASKS_FAILURE,
  error
});
