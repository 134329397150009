import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'


import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import classNames from 'classnames'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import queryString from "query-string"

//redux
import { showChildrens } from "../../actionCreators/tasks";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

const styles = (theme) => ({
  item__title: {
    textDecoration: 'underline',
    display: 'inline',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  item__desc: {
    color: '#a4a4a4',
  },
  item__value: {
    wordBreak: 'break-all'
  },
  root: {
    padding: '1rem'
  },
  fab: {
    backgroundColor: 'rgb(153, 153, 153)',
    color: '#fff',
    width: '22px',
    height: '22px',
    minHeight: 'auto',
    marginRight: '10px'
  },
  fabSvg: {
    fontSize: '20px',
  },
  item__secondary: {
    backgroundColor: '#f5f5f5'
  },
  item__thirdly: {
    backgroundColor: '#ebebeb'
  },
  item__fourthly: {
    backgroundColor: '#dfdfdf'
  },
  item__fifth: {
    backgroundColor: '#DFDFD9'
  },
  item__searched: {
    backgroundColor: '#DFDFF5'
  },
  rowValues: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  nothingFound: {
    marginTop: '2rem'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    backgroundColor: 'rgb(153, 153, 153)',
    color: '#fff',
    width: '22px',
    height: '22px',
    minHeight: 'auto',
    marginRight: '10px',
    boxShadow: 'none !important'
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  }
});

const StructureListItem = (props) => {
  const { classes, data, showChildrens, searchParams } = props;
  const handleClick = (item) => () => {
    showChildrens(item)
  };
  
  if(Array.isArray(data)) {
    return (
      <React.Fragment>
        {data.map((item)=> {
          return (
            <Paper
              square
              key={item.ID_DIV}
              className={
                classNames(
                  classes.root,
                  {
                    [classes.item__secondary]: item.depth === 2,
                    [classes.item__thirdly]: item.depth === 3,
                    [classes.item__fourthly]: item.depth === 4,
                    [classes.item__fifth]: item.depth === 5,
                    [classes.item__searched]: item.searchable
                  }
                )
                
              }
            >
              <div className={`row ${classes.rowValues}`}>
                <div className="col-sm-12">
                  {item.children && !_.get(searchParams, 'depId', '') &&
                  <Fab
                    size="small"
                    onClick={handleClick(item.ID_DIV)}
                    className={classNames(classes.expand, {
                      [classes.expandOpen]: item.show,
                    })}
                  >
                    <ExpandMoreIcon className={classes.fabSvg}/>
                  </Fab>
                  }
                  <Typography onClick={handleClick(item.ID_DIV)} className={classes.item__title}>
                    {`${item.CODE_DIV} ${item.NAME_DIV}`}
                  </Typography>
                </div>
              </div>
              {item.KOD_TYPE !== '0' &&
                <div className={`row ${classes.rowValues}`}>
                  <div className="col-md-4">
                    <Typography className={classes.item__desc}>
                      Розташування:
                    </Typography>
                    <Typography className={classes.item__value}>
                      {item.ADDRESS === '' ? '---' : item.ADDRESS}
                    </Typography>
                  </div>
                  <div className="col-md-3">
                    <Typography className={classes.item__desc}>
                      Телефон:
                    </Typography>
                    {item.PHONE
                      ?
                      <Typography component="a" href={`tel:${item.PHONE}`} className={classes.item__value}>
                        {item.PHONE}
                      </Typography>
                      :
                      <Typography className={classes.item__value}>
                        ---
                      </Typography>
                    }
                  </div>
                  <div className="col-md-3">
                    <Typography className={classes.item__desc}>
                      Електронна адреса:
                    </Typography>
                    {item.E_MAIL
                      ?
                      <Typography component="a" href={`mailto:${item.E_MAIL}`} className={classes.item__value}>
                        {item.E_MAIL}
                      </Typography>
                      :
                      <Typography className={classes.item__value}>
                        ---
                      </Typography>
                    }
                  </div>
                  
                  <div className="col-md-3">
                    <Typography className={classes.item__desc}>
                      Веб-сайт:
                    </Typography>
                   
                      {item.sites.length !== 0
                        ? _.get(item, 'sites').map((site, index) => (
                          <Typography
                            className={classes.item__value}
                            component="a"
                            rel="noopener noreferrer"
                            target="_blank"
                            href={site.url}
                            key={index}
                          >
                            {site.url}
                          </Typography>))
                        
                        :
                          <Typography>---</Typography>
                      }
                  </div>
                </div>
              }
  
              {item.show && item.children &&
              <StructureListItem
                showChildrens={showChildrens}
                classes={classes}
                data={item.children}
              />
              }
            </Paper>
          )
        })
        }
      </React.Fragment>
    )
  }
  return null;
};

class StructureList extends Component {
  static propTypes = {
    tasks: PropTypes.array,
    classes: PropTypes.object,
    showChildrens: PropTypes.func,
  };
  
  render() {
    const { tasks, classes, showChildrens, findTasks, searchParams, location } = this.props;
    
    const renderData = !searchParams.input && searchParams.select === 'default' && !searchParams.parId && !searchParams.depId  ? tasks : findTasks;
    return (
      <React.Fragment>
        {renderData.length === 0
          ?
            <Typography align="center" variant="h5" className={classes.nothingFound}>
              Нічого не знайдено
            </Typography>
          :
            <StructureListItem
              showChildrens={showChildrens}
              searchParams={searchParams}
              classes={classes}
              data={renderData}
            />
        }
        
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  findTasks: state.info.findTasks,
  searchParams: state.info.searchParams,
  
});

const mapDispatchToProps = {
  showChildrens,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(StructureList);
