// base
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// material-ui
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

function Spinner ({ classes, absolute, heightHv }) {
  return (
    <div
      className={classNames(
        classes.root,
        absolute && classes.absolute,
        heightHv && classes.heightHv
      )}
    >
      <CircularProgress size={50}/>
    </div>)
}

Spinner.propTypes = {
  classes: PropTypes.object.isRequired,
  absolute: PropTypes.bool,
  heightHv: PropTypes.bool
};

Spinner.defaultProps = {
  absolute: true
};

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    zIndex: '1',
    backgroundColor: 'rgba(255,255,255,0.5)'
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  heightHv: {
    height: '100vh'
  }
}

export default withStyles(styles)(Spinner)
