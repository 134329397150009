const axios = require('axios');

const config = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  },
};

export const getData = () => {
  if (process.env.NODE_ENV === 'development') {
    return fetch(`http://localhost:5000/data`)
      .then(response => response.json())
  }
  
  return axios.get('http://structure.sumdu.edu.ua/api/v1/departments?api_token=urjzKxIhFDhNZNcWaajN0RmTMBYbn5vzTduSKkBE', config)
    .then(response => response.data)
  
};
