import _ from 'lodash';
import {
  FIND_TASKS,
  FIND_TASKS_BY_ID,
  GET_TASKS_FAILURE,
  GET_TASKS_REQUEST,
  GET_TASKS_SUCCESS,
  SHOW_CHILDRENS,
  TOGGLE_STRUCTURES,
  CLEAR_SEARCH
} from "../types/tasks";

const initialState = {
  tasks: [],
  findTasks: [],
  searchParams: { input: '', select: 'default', parId: '', depId: '' },
  isLoading: false,
  isLoaded: false,
  error: null
};

function filterDataRec(data, predicate) {
  
  const tasks = data.map(function f(o) {
    if (predicate(o)) {
      o.searchable = true;
    }
   
    if (o.children) {
      return { ...o, children: o.children.map(f) }
    }
    
    return o;
  });
  
  return tasks
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FIND_TASKS:
      const normalizeStructures = state.tasks.map(function f(o) {
        o.show = false;
        if (o.children) {
          return { ...o, children: o.children.map(f) }
        }
        return o;
      });
      const allStructures = _.cloneDeep(normalizeStructures);
      const myExp = new RegExp(action.search.input, "ig");
      
      let data = filterDataRec(allStructures, (item) => {
        const depName = item.NAME_DIV.concat(item.ABBR_DIV);
        return (((depName.search(myExp) !== -1 && action.search.input ) &&
          (item.NAME_TYPE === action.search.select && action.search.select !== 'default'))
          || (item.NAME_TYPE === action.search.select && action.search.select !== 'default' && action.search.input === '')
          || (depName.search(myExp) !== -1 && action.search.select === 'default'))

      });
      
      let filterDate = data.filter(function f(o) {
        const depName = o.NAME_DIV.concat(o.ABBR_DIV);
        
        o.show = false;
        if (((depName.search(myExp) !== -1 && action.search.input ) &&
          (o.NAME_TYPE === action.search.select && action.search.select !== 'default'))
          || (o.NAME_TYPE === action.search.select && action.search.select !== 'default' && action.search.input === '')
          || (depName.search(myExp) !== -1 && action.search.select === 'default')

        ){
          return true;
        }

        if (o.children) {
          o.show = true;
          return (o.children = o.children.filter(f)).length
        }
      });

      return { ...state, findTasks: filterDate, searchParams: { ...action.search, parId: '', depId: '' } };
  
  
      
      
      
    case FIND_TASKS_BY_ID:
      const normalizedStructures = state.tasks.map(function f(o) {
        o.show = true;
        if (o.children) {
          return { ...o, children: o.children.map(f) }
        }
        return o;
      });
      const newAllStructures = _.cloneDeep(normalizedStructures);

      let newData = filterDataRec(newAllStructures, (item) => {
        return item.ID_DIV === parseInt(action.id, 10)
      });

      let newFilterDate = newData.filter(function f(o) {
        o.show = true;
        if (o.ID_DIV === parseInt(action.id, 10)){
          return true;
        }

        if (o.children) {
          o.show = true;
          return (o.children = o.children.filter(f)).length
        }
      });
      
      const newParams = {}
      if (action.recursive) {
        newParams.parId = action.id
      } else {
        newParams.depId = action.id
      }
      
      return { ...state, findTasks: newFilterDate, searchParams: { ...state.searchParams, ...newParams }};
  
      
      
      
      
      
      
      
      
    case SHOW_CHILDRENS:
      const structures = ( state.searchParams.input === ''
        && !state.selectOptions.includes(state.searchParams.select)
        && state.searchParams.parId === ''
      )
        
        ?
          {
            tasks: state.tasks.map(function f(o) {
              if (o.ID_DIV === action.item) {
                o.show = !o.show;
                return o;
              }
  
              if (o.children) {
                return { ...o, children: o.children.map(f) }
              }
              return o;
            })
          }
        :
          {
            findTasks: state.findTasks.map(function f(o) {
              if (o.ID_DIV === action.item) {
                o.show = !o.show;
                return o;
              }
  
              if (o.children) {
                return { ...o, children: o.children.map(f) }
              }
              return o;
            })
          } ;
      return { ...state, ...structures };
    case GET_TASKS_REQUEST:
      return { ...state, isLoading: true };
  
    case CLEAR_SEARCH:
      return { ...state, searchParams: { input: '', select: 'default', parId: '', depId: '' } };
  
    case TOGGLE_STRUCTURES:
      const tasks = state.tasks.map(function f(o) {
        o.show = action.toggle;
        if (o.children) {
          return { ...o, children: o.children.map(f) }
        }
        return o;
      });
      return { ...state, tasks };
    
    case GET_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.payload.structures,
        selectOptions: action.payload.selectOptions,
        isLoading: false,
        isLoaded: true
      };
    
    case GET_TASKS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false
      };
    default:
      return state;
  }
};
