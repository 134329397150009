import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#1b71ba',
      // lighter: blueGrey[100]
    },
    secondary: {
      main: '#1b71ba',
      // lighter: lightBlue[100]
    },
    type: 'light',
  },
})

export default theme
