import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './App.css';
import {CircleArrow as ScrollUpButton} from "react-scroll-up-button";

import Header from './components/Header'
import Controls from './components/Controls'
import Spinner from './components/Spinner'
import StructureList from './components/StructureList'
import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography'

//redux
import { findTask, getTasksRequest, findTaskByID } from "./actionCreators/tasks";
import { connect } from 'react-redux';
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core";
import queryString from "query-string";

const styles = {
  root: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  content: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  content_wrapper: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  footer: {
    flex: '0 0 auto',
    padding: '1rem'
  },
  error: {
    marginTop: '2rem'
  },
  scrollButton: {
    height: '35px !important',
    width: '35px !important',
    border: '3px solid #1b71bb !important',
    backgroundColor: 'transparent !important',
    outline: 'none !important',
    color: '#1b71bb !important',
    fill: '#1b71bb !important'
  }
};

class App extends Component {
  
  static propTypes = {
    getTasksRequest: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };
  
  componentDidMount() {
    this.props.getTasksRequest()
  }
  
  componentWillReceiveProps(nextProps, nextContext) {
    const { location, findTask, findTaskByID } = this.props;
    const values = queryString.parse(location.search);
    if(nextProps.isLoaded && !this.props.isLoaded) {
      if (values.type) {
        return findTask({ input: values.search, select: values.type });
      }
      if (values.dep_id) {
        return findTaskByID(values.dep_id)
      }
  
      if (values.par_id) {
        return findTaskByID(values.par_id, true)
      }
    }
  }
  
  render() {
    const { tasks, isLoading, error, isLoaded, classes, location, history } = this.props;
    const values = queryString.parse(location.search);
    return (
      <div className="App">
        <Header/>
        {isLoading && <Spinner absolute heightHv/>}
        <div className={classes.content}>
          {error &&
            <Typography align="center" variant="h5" className={classes.error}>
              Відбулася мережева помилка
            </Typography>
          }
          { isLoaded &&
            <div className="container">
              <Controls queryParams={values} history={history} location={location}/>
              <StructureList location={location} tasks={tasks}/>
            </div>
          }
        </div>
        <footer className={classes.footer}>
          <Typography align="center" variant="subtitle1">
            Design & Develop by <a rel="noopener noreferrer" target="_blank" href="http://web.sumdu.edu.ua/">web.sumdu.edu.ua</a>
          </Typography>
        </footer>
        <ScrollUpButton
          ContainerClassName={classes.scrollButton}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tasks: state.info.tasks,
  isLoading: state.info.isLoading,
  error: state.info.error,
  isLoaded: state.info.isLoaded,
});

const mapDispatchToProps = {
  getTasksRequest,
  findTask,
  findTaskByID
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(App);
