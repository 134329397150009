import { getTasksSuccess, getTasksFailure } from '../actionCreators/tasks';
import { GET_TASKS_REQUEST } from '../types/tasks';
import { takeEvery, call, put } from 'redux-saga/effects';
import { getData } from '../api';
import _ from 'lodash';
import parseData from '../helpers/parseData'

function* garbageRequestFlow() {
  
  try {
    const response = yield call(getData);
    const newResponse = _.sortBy(response.result, [(elem) => elem.CODE_DIV ]);
    const filtredNewResponse = newResponse.filter(elem => elem.PUBLISHED);
    const selectOptions = _.uniqBy(filtredNewResponse, 'NAME_TYPE')
      .map(elem => elem.NAME_TYPE)
      .filter(elem => !!elem);
    
    const parsedResponse = parseData(filtredNewResponse);
    yield put(getTasksSuccess({ structures: parsedResponse, selectOptions }));
  } catch (error) {
    yield put(getTasksFailure(error));
    console.log(error)
  }
}

export function* garbageRequestWatcher() {
  yield takeEvery(GET_TASKS_REQUEST, garbageRequestFlow);
}
