// base
import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

import logo from '../../img/logo-sumdu-icon.svg';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    textDecoration: 'none',
    '@media only screen and (max-width: 768px)': {
      display: 'none'
    }
  },
  logo: {
    borderRight: '1px solid #d9d9d9',
    marginRight: '20px'
  },
  toolbar: {
    paddingRight: '0',
    paddingLeft: '0',
  },
  button: {
    marginLeft: 'auto',
    textAlign: 'center',
    border: '2px solid #1b71ba',
    borderRadius: '50px',
    marginRight: '15px',
    color: '#1b71ba',
    '&:hover': {
      color: '#fff',
      background: '#1b71ba',
      
    }
  }
};

function Header(props) {
  const { classes } = props;
  
  return (
    <div className={classes.root}>
      <AppBar position="static" color="inherit">
        <Toolbar classes={{ root: classes.toolbar }}>
          <a href="https://sumdu.edu.ua/" rel="noopener noreferrer" target="_blank">
            <Tooltip title="Сайт СумДУ" aria-label="Сайт СумДУ">
              <img src={logo} alt="Сайт СумДУ" className={classes.logo} />
            </Tooltip>
          </a>
          <Typography
            component="a"
            href="/"
            variant="subtitle1"
            align="left"
            className={classes.grow}
            color="inherit"
          >
            Організаційна структура Сумського державного универсітету
          </Typography>
          <Button
            component="a"
            rel="noopener noreferrer"
            href="http://normative.sumdu.edu.ua/normative-base.html?task=getfile&tmpl=component&id=9c0714f6-a8b2-e011-856b-001a4be6d04a&kind=1"
            target="_blank"
            className={classes.button}
          >
            PDF версія
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
