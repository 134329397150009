export default (data) => {
  const nestedList = getNestedChildren(data,null);
  return nestedList
}

function getNestedChildren(arr, parent, depthP) {
  let children = [];
  if (depthP) depthP ++;
  let depth = depthP || 1;
  for(let i = 0; i < arr.length; ++i) {
    if(arr[i].ID_PAR === parent) {
      arr[i].depth = depth;
      let grandChildren = getNestedChildren(arr, arr[i].ID_DIV, depth);
      
      if(grandChildren.length) {
        arr[i].show = false;
        arr[i].children = grandChildren;
        
      }
      children.push( arr[i]);
    }
  }
  return children;
}
