import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import queryString from 'query-string'

//redux
import { findTask, toggleStructures, clearSearch } from "../../actionCreators/tasks";
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import InputBase from '@material-ui/core/InputBase'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

const styles = {
  root: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  grow: {
    flexGrow: 1,
  },
  menu: {
    maxHeight: '400px',
  },
  menuPaper: {
    width: '200px'
  },
  input: {
    border: '1px solid #d9d9d9',
    padding: '6px 10px',
    width: '100%',
    outline: 'none',
    background: '#fff',
    color: '#666',
    '@media only screen and (max-width: 420px)': {
      fontSize: '0.79rem'
    }
  },
  listItem: {
    border: '1px solid #d9d9d9',
    background: '#fff',
    position: 'relative'
  },
  iconContainer: {
    position: 'absolute',
    right: '0',
    top: '0',
    bottom: '0',
    height: '100%',
    width: '46px',
    fontSize: '2.2rem',
    color: '#fff',
    background: '#1b71bb',
    textAlign: 'center'
  },
  inputContainer: {
    marginTop: '8px',
    marginBottom: '8px',
    position: 'relative',
    width: '100%'
  },
  inputIconContainer: {
    position: 'absolute',
    right: '0',
    top: '0',
    bottom: '0',
    height: '100%',
    width: '46px',
    fontSize: '2.2rem',
    color: '#fff',
    background: '#1b71bb',
    textAlign: 'center'
  },
  closeIconContainer: {
    position: 'absolute',
    right: '46px',
    top: '0',
    bottom: '0',
    height: '100%',
    width: '46px',
    fontSize: '2.2rem',
    textAlign: 'center'
  },
  fabContainer: {
    position: 'relative',
  },
  fab: {
    left: '-15px',
    top: 'calc(50% - 20px)',
    '@media only screen and (max-width: 768px)': {
      right: '0',
      left: 'auto'
    }
  }
  
};

class Controls extends Component {
  static propTypes = {
    findTask: PropTypes.func.isRequired,
    clearSearch: PropTypes.func.isRequired,
    location: PropTypes.object,
  };
  
  constructor (props) {
    super(props)
    
    this.state = {
      input: _.get(props, 'queryParams.search', '') && _.get(props, 'queryParams.type', '')
        ?_.get(props, 'queryParams.search', '') : '',
      select:_.get(props, 'queryParams.search', '') && _.get(props, 'queryParams.type', '') ?
        _.get(props, 'queryParams.type', 'default') : 'default',
      anchorEl: null,
      toggleStructures: false
    };
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (((prevState.input !== this.state.input) || (prevState.select !== this.state.select))
      && (this.state.input === '' && this.state.select === 'default') && !!this.props.location.search) {
      this.props.history.replace({
        search: ''
      });
    }
  }
  
  handleClickListItem = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  
  handleToggleStructures = _.debounce((value) => {
    const { toggleStructures } = this.props;
    toggleStructures(value);
    this.setState({  toggleStructures: value });
  }, 300);
  
  
  
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  
  handleOuterChange = _.debounce((str) => {
    const { findTask, location, history } = this.props;
    const { select } = this.state;
    if (!!str) {
      history.replace({
        search: `?search=${str}&type=${select}`
      });
    }
    
    return findTask({ input: str, select });
  }, 100);
  
  handleChange = (str) => {
    this.setState({
      input:  str
    });
    this.handleOuterChange(str);
  };
  
  handleChangeSelect = (value) => {
    const { findTask, history } = this.props;
    const { input } = this.state;
    this.setState({
      select:  value,
      anchorEl: null
    });
    history.replace({
      search: `?search=${input}&type=${value}`
    });
    findTask({ select: value, input })
  };
  
  handleClearSearch = () => {
    const { clearSearch, history } = this.props;
    history.replace({
      search: ''
    });
    this.setState({
      input: '',
      select: 'default'
    });
    clearSearch()
  };
  
  handleSelectValue = (select) => {
    if (select === 'default') return 'Усі підрозділи';
    return `${_.upperFirst(select)}`
  };
  
  render() {
    const { classes, selectOptions, searchParams } = this.props;
    const { anchorEl, select, input, toggleStructures } = this.state;
    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className="row">
            <div className="col-md-4">
              <List component="nav">
                <ListItem
                  className={classes.listItem}
                  button
                  aria-haspopup="true"
                  aria-controls="lock-menu"
                  aria-label="When device is locked"
                  onClick={this.handleClickListItem}
                >
                  <ListItemText
                    primary={this.handleSelectValue(select)}
                  />
                  <div className={classes.iconContainer}>
                    <UnfoldMoreIcon/>
                  </div>
                </ListItem>
              </List>
            </div>
            
            <div className="col-md-7">
              <div className={classes.inputContainer}>
                <InputBase
                  value={input}
                  classes={{ root: classes.input }}
                  onChange={(e) => this.handleChange(e.target.value)}
                  placeholder="Введіть назву структурного підрозділу"
                />
                {(input !== '' || select !== 'default') &&
                <div className={classes.closeIconContainer}>
                  <CloseIcon onClick={this.handleClearSearch} />
                </div>
                }
                <div className={classes.inputIconContainer}>
                  <SearchIcon />
                </div>
              </div>
            </div>
            <div className={`col-md-1 ${classes.fabContainer}`}>
              {(input === '' && select === 'default' && searchParams.parId === '' && searchParams.depId === '') &&
                <Tooltip title="Розгорнути усе" aria-label="Розгорнути усе ">
                  <Fab
                    size="small"
                    color="secondary"
                    className={classes.fab}
                    onClick={() => this.handleToggleStructures(!toggleStructures)}
                  >
                    {toggleStructures
                      ? <KeyboardArrowUpIcon/>
                      : <KeyboardArrowDownIcon/>
                    }
                  </Fab>
                </Tooltip>
              }
            </div>
          </div>
          
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            className={classes.menu}
            classes={{ paper: classes.menuPaper }}
          >
            <MenuItem selected={select === "default"} onClick={() => this.handleChangeSelect('default')}>
              Усі підрозділи
            </MenuItem>
            {selectOptions.map((option, index) => (
              <MenuItem
                key={index}
                selected={option === select}
                onClick={() => this.handleChangeSelect(option)}
              >
                {_.upperFirst(option)}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  selectOptions: state.info.selectOptions,
  searchParams: state.info.searchParams,
});

const mapDispatchToProps = {
  findTask,
  toggleStructures,
  clearSearch
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Controls);
